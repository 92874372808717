import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-page-header',
  templateUrl: './page-header-component.component.html',
  styleUrls: ['./page-header-component.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() pageName: string;
  @Input() pageColour: string;
  constructor() {}

  ngOnInit() {}
}
