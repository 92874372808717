import { Component, OnInit } from '@angular/core';
import { SmallImageButton } from 'src/app/lib/data/models/ui/smallImageButton';

@Component({
  selector: 'ui-app-header',
  templateUrl: './app-header-component.component.html',
  styleUrls: ['./app-header-component.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  constructor() {}

  profileButton: SmallImageButton = {
    link: '/profile',
    imageName: 'user-profile.png',
    backgroundColour: '#4EB89E',
    borderColour: 'white',
  };

  settingsButton: SmallImageButton = {
    link: '/settings',
    imageName: 'settings.png',
    backgroundColour: '#4EB89E',
    borderColour: 'white',
  };
  ngOnInit() {}
}
