import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from './guards/auth.guard';
// import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome', // TODO: Set this to ''
    pathMatch: 'full',
  },
  {
    path: 'challenges',
    loadChildren: () =>
      import('./pages/challenges/challenges.module').then(
        (m) => m.ChallengesModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'my-body',
    loadChildren: () =>
      import('./pages/my-body/my-body.module').then((m) => m.MyBodyModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'dash',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'goals',
    loadChildren: () =>
      import('./pages/goals/goals.module').then((m) => m.GoalsModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'journals',
    loadChildren: () =>
      import('./pages/journals/journals.module').then((m) => m.JournalsModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./pages/public/intro/intro.module').then((m) => m.IntroModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/secure/secure.module').then((m) => m.SecureModule),
    // canActivate: [AuthGuard] // Secure all child pages
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/public/welcome/welcome.module').then(
        (m) => m.WelcomePageModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./pages/public/signin/signin.module').then(
        (m) => m.SigninPageModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/public/signup/signup.module').then(
        (m) => m.SignupPageModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () =>
      import('./pages/public/password-reset/password-reset.module').then(
        (m) => m.PasswordResetPageModule
      ),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
