import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UIExampleComponent } from './components/example/example.component';
import { AppHeaderComponent } from './components/app-header-component/app-header-component.component';
import { ExtraSmallImageButtonComponent } from './components/extra-small-image-button/extra-small-image-button.component';
import { AppFooterComponent } from './components/app-footer-nav-component/app-footer-nav-componentcomponent';
import { AppButtonShelfComponent } from './components/button-holding-container/button-holding-container.component';
import { ImageButtonComponent } from './components/image-button/image-button.component';
import { MyProfileComponent } from './modals/my-profile/my-profile.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { BasicTextButtonComponent } from './components/basic-text-button/basic-text-button.component';
import { ViewPublicJournalsComponent } from './view-public-journals/view-public-journals.component';
import { CircleImageButtonComponent } from './components/circle-image-button/circle-image-button.component';
import { RectangleImageButtonComponent } from './components/image-rectangle-button/image-rectangle-button.component';
import { ChallengeBasicListItemComponent } from './components/challenge-basic-list-item/challenge-basic-list-item.component';
import { ViewChallengeModalComponent } from './components/view-challenge-modal/view-challenge-modal.component';
import { ActivityListItemComponent } from './components/activity-list-item/activity-list-item.component';
import { GoalsGridContainerComponent } from './components/goals-grid-container/goals-grid-container.component';
import { GoalListItemComponent } from './components/goal-list-item/goal-list-item.component';
import { PageHeaderComponent } from './components/page-header-component/page-header-component.component';
import { ExtraLargeRectangleButtonComponent } from './components/extra-large-rectangle-button/extra-large-rectangle-button.component';
import { JournalListItemComponent } from './components/journal-list-item/journal-list-item.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule],
  declarations: [
    UIExampleComponent,
    AppHeaderComponent,
    ExtraSmallImageButtonComponent,
    AppFooterComponent,
    AppButtonShelfComponent,
    ImageButtonComponent,
    GoalListItemComponent,
    GoalsGridContainerComponent,
    ViewProfileComponent,
    MyProfileComponent,
    CircleImageButtonComponent,
    JournalListItemComponent,
    ActivityListItemComponent,
    ExtraLargeRectangleButtonComponent,
    PageHeaderComponent,
    ChallengeBasicListItemComponent,
    BasicTextButtonComponent,
    BasicTextButtonComponent,
    RectangleImageButtonComponent,
    ViewPublicJournalsComponent,
    ViewChallengeModalComponent,
  ],
  exports: [
    UIExampleComponent,
    AppFooterComponent,
    ViewProfileComponent,
    AppHeaderComponent,
    GoalListItemComponent,
    PageHeaderComponent,
    ExtraSmallImageButtonComponent,
    ChallengeBasicListItemComponent,
    ExtraLargeRectangleButtonComponent,
    AppButtonShelfComponent,
    CircleImageButtonComponent,
    GoalsGridContainerComponent,
    JournalListItemComponent,
    ActivityListItemComponent,
    ImageButtonComponent,
    BasicTextButtonComponent,
    RectangleImageButtonComponent,
    MyProfileComponent,
    BasicTextButtonComponent,
    ViewChallengeModalComponent,
    ViewPublicJournalsComponent,
  ],
})
export class UIModule {}
